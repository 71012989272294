import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import Fade from 'react-reveal/Fade';

const IndexPage = () => (
  <Layout>
    <div className="content content__home">
      <div className="content__container">
        <h1>Work</h1>
          <div className="home__galleryitem">
            <Link to="/projects/barton-perreira" className="galleryitem__image">
            <img className="image"
                  sizes="(min-width: 1024px) 1024px, 100vw"
                  srcset="img/barton-perreira/barton-gallery-image-500.png 500w,
                          img/barton-perreira/barton-gallery-image-800.png 800w,
                          img/barton-perreira/barton-gallery-image-1200.png 1200w"                  
                  src="img/barton-perreira/barton-gallery-image.png"
                  alt="Screenshot of Barton Perreira website" />

            </Link>
            <Link to="/projects/barton-perreira" className="galleryitem__link">
              Barton Perreira
            </Link>
          </div>
        <Fade bottom>
          <div className="home__galleryitem">
            <Link to="/projects/api-international" className="galleryitem__image">
            <img className="image"
                  sizes="(min-width: 1024px) 1024px, 100vw"
                  srcset="img/api-international/apiint-gallery-image-500.png 500w,
                          img/api-international/apiint-gallery-image-800.png 800w,
                          img/api-international/apiint-gallery-image-1200.png 1200w"                  
                  src="img/api-international/apiint-gallery-image.png"
                  alt="Screenshot of API International website" />
            </Link>
            <Link to="/projects/api-international" className="galleryitem__link">
              API International
            </Link>
          </div>
          <div className="home__galleryitem">
            <Link to="/projects/popup-arcade" className="galleryitem__image">
            <img className="image"
                  sizes="(min-width: 1024px) 1024px, 100vw"
                  srcset="img/popup-arcade/popup-gallery-image-500.png 500w,
                          img/popup-arcade/popup-gallery-image-800.png 800w,
                          img/popup-arcade/popup-gallery-image-1200.png 1200w"                  
                  src="img/popup-arcade/popup-gallery-image.png"
                  alt="Screenshot of Popup Arcade website" />
            </Link>
            <Link to="/projects/popup-arcade" className="galleryitem__link">
              Popup Arcade
            </Link>
          </div>
        </Fade>
      </div>
    </div>
  </Layout>
)

export default IndexPage